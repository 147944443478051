.file-upload-button {
  position: absolute;
  overflow: hidden;
  top: 14px;
  left: 38px;
  overflow: hidden;
}
.file-upload-button input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.writesomt_full_send {
  display: flex;
  padding: 15px;
  position: relative;
}
.channel-list {
  height: 454px;
  overflow: scroll;
  overflow-x: hidden;
}
.sent_sidemain-top {
  height: auto;
  overflow: auto;
  padding: 0 15px;
  margin-top: 0;
  /* flex-wrap: wrap;
    align-content: end;
    display: flex;
    align-items: flex-end; */
}
.msg-list {
  width: 100%;
}

.file-upload-button .btn {
  background: transparent;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  min-width: 25px;
  min-height: 28px;
  line-height: 26px;
  padding: 0 0px;
}
.file-upload-button .btn .fa {
  color: #0000008c;
}
.chatBottom_parent .writesomt_full input {
  padding-left: 68px;
}
.sent_sidemain-top1 {
  height: auto;
  max-height: 300px;
  overflow: auto;
  padding: 0 30px;
}
.writesomt_smiley .EXKLM {
  position: absolute !important;
}
.writesomt_smiley .HJDRz {
  height: 100% !important;
  max-height: 200px;
}

@media screen and (max-width: 767px) {
  .sent_sidemain-top1 {
    max-height: 100%;
  }
  .sent_sidemain-top1 {
    /* padding-bottom: 50px; */
  }
}
