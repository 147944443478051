
.file-upload-button {
    position: absolute;
    overflow: hidden;
    top: 14px;
    left: 34px;
    overflow: hidden;
}
.file-upload-button input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}


.file-upload-button .btn {
    background: transparent;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    min-width: 25px;
    min-height: 28px;
    line-height: 26px;
    padding: 0 0px;

}
.file-upload-button .btn .fa{
    color: #0000008c; font-size: 18px;
}
.chatBottom_parent .writesomt_full input {
    padding-left: 68px;
}

.file-upload-button {
    position: absolute;
    overflow: hidden;
    top: 14px;
    left: 34px;
    overflow: hidden;
}
.file-upload-button input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}


.file-upload-button .btn {
    background: transparent;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    min-width: 25px;
    min-height: 28px;
    line-height: 26px;
    padding: 0 0px;

}
.file-upload-button .btn .fa{
    color: #0000008c;
}
.chatBottom_parent .writesomt_full input {
    padding-left: 68px;
}


.writesomt_full{
    position: relative;
    align-items: center;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    background-color: #fff;
    padding: 0 28px 0 21px;
}

.writesomt_full input {
    width: 100%; padding: 0;
    /* padding: 0px 15px 0 30px; */
    border: 0;
}

/* .sent_sidemain{
    padding: 0 0px 20px 20px;
} */
